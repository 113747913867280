import { StaticImage } from "gatsby-plugin-image"
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout";

const PaymentSuccess = ({resultMessage}) => {

    let errorMessage = null;

    if(resultMessage) {
        errorMessage = resultMessage;
    }
    return (

    <Layout
        staticSeo={{ seoTitle: "Payment failure", seoDescription: "Payment failure" }}
        location={{ pathname: "payment-failure" }}
    >
        <Container>
            <Row className={` d-flex justify-content-center`}>
                <Col
                    lg={10}
                    xl={8}
                    className="d-flex flex-column align-items-center"
                >
                    <h1 className="mt-5 pt-5">Sorry, something went wrong</h1>
                    {
                        errorMessage &&
                        <Fragment>
                            <p className="h5 py-3 py-md-4 py-lg-5">Reason for failure: {errorMessage}</p>
                        </Fragment>
                    }
                    <p>
                        <a className="h5 text-primary" href="/">
                            Return to homepage
                        </a>
                    </p>
                    <div className={` py-3 py-md-4 py-lg-5`}>
                        <StaticImage
                          src="../images/bicycle-accident.png"
                          alt="Bicycle crash"
                          placeholder="none"
                          width={500}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
    )

}
export default PaymentSuccess
